import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { DateAdapter } from '@angular/material/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { DataintegrationService } from 'src/app/services/dataintegration-catalog/dataintegration.service';
import { NavigatorService } from 'src/app/services/navigator.service';
import { GenericDetailComponent } from 'src/app/shared/GenericDetailComponent';
import { DataIntegrationDto } from 'src/app/shared/dto/dataintegration-catalog/DataIntegrationDto';
import { dataintegrationStatus } from 'src/app/shared/dto/dataintegration-catalog/DataIntegrationStatus';
import { TimestampFormatPipe } from 'src/app/shared/pipes/timestampFormatPipe';
import { PositionContainerType, positionContainerTypes } from './../../shared/dto/core-wallets/positionContainerType.types';
import { exchangeTypes } from 'src/app/shared/dto/commons/ExchangeType.types';
import { blockchainTypes } from 'src/app/shared/dto/commons/BlockchainType.types';

@Component({
  selector: 'app-dataintegration-detail',
  templateUrl: './dataintegration-detail.component.html',
  styleUrls: ['./dataintegration-detail.component.scss'],
  providers: [
    DataintegrationService
  ]
})
export class DataintegrationDetailComponent extends GenericDetailComponent implements OnInit, OnDestroy {

  readonly dataintegrationStatus = dataintegrationStatus;
  readonly positionContainerTypes = positionContainerTypes;
  readonly exchangeTypes = exchangeTypes;
  readonly blockchainTypes = blockchainTypes;

  selectOptions: Observable<string[]>;
  isDetail: boolean = false;

  private _unsubscribeAll: Subject<void> = new Subject<void>();

  // eslint-disable-next-line @typescript-eslint/member-ordering
  typesOptions: Observable<string[]>;

  constructor(
    private dataintegrationService: DataintegrationService,

    activeRoute: ActivatedRoute,
    dialog: MatDialog,
    snackBar: MatSnackBar,
    timestampFormatPipe: TimestampFormatPipe,
    navigatorService: NavigatorService,
    router: Router,
    dateAdapter: DateAdapter<Date>,
    private _formBuilder: FormBuilder
    ) {
      super(
        navigatorService,
        dialog,
        router,
        dateAdapter,
        activeRoute,
        snackBar,
        timestampFormatPipe
);
  }

  ngOnInit(): void {
    if(this.detailId()) {
      this.isDetail = true;
      this.read(this.detailId());
    } else {
      this.form = this.loadForm(new DataIntegrationDto());
    }
  }

  ngOnDestroy(): void {
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  save(): void {
    this.form.markAsPristine();
    if(this.detailId()) {
      this.update(this.toDataIntegrationDto());
      return;
    }
    this.create(this.toDataIntegrationDto());
  }

  deleteDataIntegration(): void {
    if(this.detailId()) {
      this.confirm('Sei sicuro di voler cancellare il data integration?')
        .pipe(
          takeUntil(this._unsubscribeAll),
          filter(choice => choice)
        ).subscribe(() => this.delete(this.detailId()));
    }
  }

  detailId(): number {
    return +this.activeRoute.snapshot.paramMap.get('id');
  }

  selectedName(name: string): void {
    if(this.form) {
      const nameFormControl = this.form.get('dataIntegrationName');
      const typeFormControl = this.form.get('dataIntegrationType');
      typeFormControl.setValue(this.exchangeTypes.includes(name) ? PositionContainerType.EXCHANGE_ACCOUNT : PositionContainerType.WALLET);
      nameFormControl.setValue(name);
    }
  }

  private toDataIntegrationDto(): DataIntegrationDto {
    const formData = this.form.getRawValue();
    return {...formData, ...formData.checkboxGroup} as DataIntegrationDto;
  }

  private read(id: number): void {
    this.dataintegrationService.read(id)
    .pipe(
      takeUntil(this._unsubscribeAll)
    ).subscribe(dataIntegration => this.form = this.loadForm({...new DataIntegrationDto(), ...dataIntegration}));
  }

  private create(dataIntegration: DataIntegrationDto): void {
    this.dataintegrationService.create(dataIntegration).subscribe((responseDataIntegration) => {
      this.router.navigateByUrl(this.router.url.replace(this.detailId() + '', responseDataIntegration.id + ''));
      this.form = this.loadForm({...new DataIntegrationDto(), ...responseDataIntegration});
    });
  }

  private update(dataIntegration: DataIntegrationDto): void {
    this.dataintegrationService.update(dataIntegration).subscribe(() => this.read(this.detailId()));
  }

  private delete(id: number): void {
    this.dataintegrationService.delete(id).subscribe(() => this.router.navigate(['/dataintegration/']));
  }

  private loadForm(dataIntegration: DataIntegrationDto): FormGroup {

    const formGroup = this._formBuilder.group({...dataIntegration, ...this.formValidators(dataIntegration)});
    const checkboxGroup = new FormGroup({
      dataIntegrationIsCSV: new FormControl(dataIntegration.dataIntegrationIsCSV),
      dataIntegrationIsAPI: new FormControl(dataIntegration.dataIntegrationIsAPI)
    }, this.requireCheckboxesToBeCheckedValidator());
    formGroup.addControl('checkboxGroup', checkboxGroup);

    return formGroup;
  }

  private formValidators(dataIntegration: DataIntegrationDto): any {
    const urlRegex = /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/;
    return {
      dataIntegrationName: [dataIntegration.dataIntegrationName, [Validators.required, Validators.maxLength(100)]],
      dataIntegrationType: [dataIntegration.dataIntegrationType, Validators.required],
      dataIntegrationIconUrl: [dataIntegration.dataIntegrationIconUrl, Validators.pattern(urlRegex)],
      dataIntegrationGuideUrl: [dataIntegration.dataIntegrationGuideUrl, Validators.pattern(urlRegex)],
      keyList: [dataIntegration.keyList],
    };
  }


  private requireCheckboxesToBeCheckedValidator(minRequired = 1): ValidatorFn {
    return (formGroup: FormGroup): any => {
      let checked = 0;

      Object.keys(formGroup.controls).forEach((key) => {
        const control = formGroup.controls[key];

        if (control.value === true) {
          checked ++;
        }
      });

      if (checked < minRequired) {
        return {
          requireOneCheckboxToBeChecked: true,
        };
      }

      return null;
    };
  }
}
